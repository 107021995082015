import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ServiceService } from 'src/app/service.service';

declare var $: any

@Component({
  selector: 'app-list-faq-withdral',
  templateUrl: './list-faq-withdral.component.html',
  styleUrls: ['./list-faq-withdral.component.css']
})
export class ListFaqWithdralComponent implements OnInit {

  currTab: any = 'English';
  faqData: any = [];
  pageNumber: number = 1
  faqId: any;
  isPublished: any;
  lan: any;
  totalRecords: any;
  itemsPerPage: number = 10;
  question: any = ''
  constructor(public route: Router, public service: ServiceService) { }

  ngOnInit(): void {
    this.faqList()
  }

  // tab function
  selectTab(tab) {
    this.currTab = tab;
    if (this.currTab === 'English') {
      this.faqList()
    }
    else if (this.currTab === 'German') {
      this.faqListGerman()
      console.log('hh', this.currTab);


    }
    else if (this.currTab === 'Spanish') {
      this.faqListSpanish();

    }

  }
  // let url = `account/admin/user-management/filter-user-details?page=${
  //   this.pageNumber - 1
  // }
  // ${
  //   this.userForm.value.searchText
  //     ? "&search=" + search
  //     : ""
  // } 
  // ${this.userForm.value.startdate ? "&fromDate=" + startdate : ""}
  // ${this.userForm.value.enddate ? "&toDate=" + enddate : ""}
  // ${this.userForm.value.country ? "&country=" + countryDa : ""}
  // ${
  //   this.userForm.value.userStatus
  //     ? "&status=" + this.userForm.value.userStatus
  //     : ""
  // }
  // `;
  // faq english
  faqList() {
    let url = `wallet/admin/get-FAQ-list?page=${this.pageNumber - 1}&pagesize=${this.itemsPerPage}`
    // var url = "static/search-filter-faq-list?page="+(this.pageNumber-1)+ "&pageSize=10"  ;
    // var url = `static/admin/static-content/get-FAQ-list`
    this.service.showSpinner();
    this.service.get(url).subscribe((res: any) => {
      console.log("kyc", res);
      if (res.status == 200) {
        this.service.hideSpinner()
        this.faqData = res.data['List']['content'];
        this.totalRecords = res.data.Count ? res.data.List.totalElements : '';
      }

      this.service.hideSpinner()

    }, (err) => {
      if (err['status'] == 401) {
        this.service.hideSpinner()
        this.service.toasterErr('Unauthorized Access')
      }
      else {
        this.service.hideSpinner()
        this.service.toasterErr('Something Went Wrong');
      }
    })
  }
  isSearched: boolean = false;

  pagination(page) {
    // this.totalRecords = [];
    console.log("jh", page);
    this.pageNumber = page;
    console.log("jh", this.pageNumber);
    if (this.isSearched) {


      this.faqList();
    } else {
      this.faqList();
    }
  }
  // faq german
  faqListGerman() {
    var url = "static/search-filter-german-faq-list?page=" + (this.pageNumber - 1) + "&pageSize=10";
    this.service.get(url).subscribe((res: any) => {
      console.log("kyc", res);
      if (res.status == 200) {
        this.faqData = res.data;
        this.totalRecords = res.data.totalCount;

      }

    }, (err) => {
      if (err['status'] == 401) {
        this.service.toasterErr('Unauthorized Access')
      }
      else {
        this.service.toasterErr('Something Went Wrong');
      }
    })
  }

  // faq spanish
  faqListSpanish() {
    var url = "static/search-filter-spanish-faq-list?page=" + (this.pageNumber - 1) + "&pageSize=10";
    this.service.get(url).subscribe((res: any) => {
      console.log("kyc", res);
      if (res.status == 200) {
        this.faqData = res.data;
      }

    }, (err) => {
      if (err['status'] == 401) {
        this.service.toasterErr('Unauthorized Access')
      }
      else {
        this.service.toasterErr('Something Went Wrong');
      }
    })
  }
  // add faq
  addFaq() {
    this.route.navigate(['/add-faq-withdral'])
  }

  // edit faq
  editFaq(faqId) {
    this.route.navigate(['/edit-faq-withdral'], { queryParams: { faqId: faqId } })
  }

  viewFaq(faqId) {
    this.route.navigate(['/view-faq-withdral'], { queryParams: { faqId: faqId } })
  }
  // open modal
  delete(faqId) {
    this.faqId = faqId;
    $('#deleteModal').modal('show')
  }



  // delete functionallity of faq english
  deleteUser() {
    let url = "wallet/admin/delete-FAQ?faqId=" + this.faqId;
    this.service.showSpinner();
    this.service.delete(url).subscribe((res: any) => {
      if (res.status == 200) {
        this.service.hideSpinner();
        $('#deleteModal').modal('hide')
        this.service.toasterSucc(res.message);
        this.faqList();
      }
    }, (err) => {
      if (err['status'] == 401) {
        this.service.toasterErr('Unauthorized Access')
      }
      else {
        this.service.toasterErr('Something Went Wrong');
      }
    })
  }


  // open modal
  publish(publish, faqId, tab) {
    this.faqId = faqId;
    this.isPublished = publish;
    this.lan = tab
    console.log('gff', this.isPublished);

    $('#publishModal').modal('show')

  }

  // publish faq language
  publishFaqLanguage() {
    if (this.lan === 'English') {
      this.publishFaq()
    }
    else if (this.lan === 'German') {
      this.publishFaqGerman()
      console.log('hh', this.lan);


    }
    else if (this.lan === 'Spanish') {
      this.publishFaqSpanish();

    }
  }

  // publish faq english
  publishFaq() {
    let url = "static/publish-unpuqblish-faq?faqId=" + this.faqId;
    let data = {}
    this.service.showSpinner();
    this.service.post(url, data).subscribe((res: any) => {
      if (res.status == 200) {
        this.service.hideSpinner();
        $('#publishModal').modal('hide')
        this.service.toasterSucc(res.message);
        this.faqList();
      }
    }, (err) => {
      if (err['status'] == 401) {
        this.service.toasterErr('Unauthorized Access')
      }
      else {
        this.service.toasterErr('Something Went Wrong');
      }
    })
  }

  // publish faq german
  publishFaqGerman() {
    let url = "static/publish-unpuqblish-german-faq?faqId=" + this.faqId;
    let data = {}
    this.service.showSpinner();
    this.service.post(url, data).subscribe((res: any) => {
      if (res.status == 200) {
        this.service.hideSpinner();
        $('#publishModal').modal('hide')
        this.service.toasterSucc(res.message);
        this.faqListGerman();
      }
    }, (err) => {
      if (err['status'] == 401) {
        this.service.toasterErr('Unauthorized Access')
      }
      else {
        this.service.toasterErr('Something Went Wrong');
      }
    })
  }

  // publish faq spanish
  publishFaqSpanish() {
    let url = "static/publish-unpuqblish-spanish-faq?faqId=" + this.faqId;
    let data = {}
    this.service.showSpinner();
    this.service.post(url, data).subscribe((res: any) => {
      if (res.status == 200) {
        this.service.hideSpinner();
        $('#publishModal').modal('hide')
        this.service.toasterSucc(res.message);
        this.faqListSpanish();
      }
    }, (err) => {
      if (err['status'] == 401) {
        this.service.toasterErr('Unauthorized Access')
      }
      else {
        this.service.toasterErr('Something Went Wrong');
      }
    })
  }
  edit() { }
  view() { }

  isAssending: boolean = true;


  sortAgent(key) {
    if (this.isAssending) {
      this.faqData = this.faqData.sort((a, b) => {
        return a[key] > b[key] ? 1 : -1;
      });
    } else {
      this.faqData = this.faqData.sort((a, b) => {
        return a[key] < b[key] ? 1 : -1;
      });
    }
  }

  // search advertisement
  search() {
    this.faqData = [];
    this.totalRecords = 0
    this.pageNumber = 1;
    // if (this.question) {
    //   // this.faqList()
    //   this.faqData = res.data
    // } else {
    // }
    let url = `wallet/admin/get-FAQ-list?page=${this.pageNumber - 1}&pagesize=${this.itemsPerPage}${this.question ? "&question=" + this.question : ""}`
    // var url = "static/search-filter-faq-list?page="+(this.pageNumber-1)+ "&pageSize=10"  ;
    // var url = `static/admin/static-content/get-FAQ-list`
    this.service.showSpinner();
    this.service.get(url).subscribe((res: any) => {
      console.log("kyc", res);
      if (res.status == 200) {
        this.faqData = res.data;
        this.totalRecords = res.data.Count ? res.data.List.totalElements : '';


      }

      this.service.hideSpinner()

    }, (err) => {
      if (err['status'] == 401) {
        this.service.hideSpinner()
        this.service.toasterErr('Unauthorized Access')
      }
      else {
        this.service.hideSpinner()
        this.service.toasterErr('Something Went Wrong');
      }
    })
  }

  // reset search fields
  reset() {
    this.faqData = [];
    this.totalRecords = 0
    this.pageNumber = 1;
    if (this.question) {
      this.question = ''
      this.faqList()
    } else {
    }
  }

  // pagination
  // pagination(page) {
  //   this.currentPage = page
  //   this.getAdvertisement()
  // }

}
