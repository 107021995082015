import { Component, OnInit } from '@angular/core';
import { ServiceService } from '../service.service';
import { ActivatedRoute, Router } from '@angular/router';
@Component({
  selector: 'app-disclaimer',
  templateUrl: './disclaimer.component.html',
  styleUrls: ['./disclaimer.component.css']
})
export class DisclaimerComponent implements OnInit {
  terms:any=[];
  id: any;
  dataas: any=[];
  constructor(public service:ServiceService, public route: ActivatedRoute,private router:Router) { }

  ngOnInit() {
    this.route.params.subscribe(x=>{

      this.id= x['id'];
    })
    this.getListCode();
  }


  // Get List Code
  getListCode(){
    this.service.showSpinner();
    this.service.get('static/get-static-page-data?pageKey=DISCLAIMER').subscribe(res=>{

      this.service.hideSpinner();
      if(res['status']== 200){
        setTimeout(() => {
          this.dataas = res['data'];
        }, 500);
        // this.terms = data.filter(x=>(x.staticContentId == this.id))

      }
    }, err=>{

      this.service.hideSpinner();
      if(err['status']=='401'){
        this.service.onLogout();
        this.service.toasterErr('Unauthorized Access');
      }else{
      this.service.toasterErr('Something Went Wrong');
   }
    })
  }

 // Save Abou Us Functionality
 saveTerms(){
  var apiReq = {
    "pageKey": "Disclaimer",
   "pageData": this.dataas.pageData
 }
//  var apiReq = {
//   "pageKey": "Privacy Policy",
//   "pageData": this.dataa.pageData
// }
// this.service.showSpinner();
// this.service.post('static/update-static-content-data',apiReq).subscribe(res=>{
 this.service.showSpinner();
 this.service.post('static/update-static-content-data',apiReq).subscribe(res=>{

   this.service.hideSpinner();
   if(res['status']== 200){
     this.getListCode();
     this.router.navigate[('/statics-content')]
    this.service.toasterSucc('Disclaimer Updated Successfully')
   }else{
     this.service.toasterErr('Disclaimer Not Updated ')
   }
 }, err=>{

   this.service.hideSpinner();
   if(err['status']=='401'){
     this.service.onLogout();
     this.service.toasterErr('Unauthorized Access');
   }else{
   this.service.toasterErr('Something Went Wrong');
}
 })
 }

}
