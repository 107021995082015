import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiceService } from 'src/app/service.service';

@Component({
  selector: 'app-add-faq-withdral',
  templateUrl: './add-faq-withdral.component.html',
  styleUrls: ['./add-faq-withdral.component.css']
})
export class AddFaqWithdralComponent implements OnInit {
  addFaqForm: FormGroup;
  pageNumber: number = 1
  currTab: any;
  constructor(public route: Router, public service: ServiceService, public active: ActivatedRoute) {
    this.active.queryParams.subscribe((params) => {
      this.currTab = params.tab
      console.log('jjf', this.currTab);

    })
  }

  ngOnInit(): void {
    this.formValidation();
  }

  formValidation() {
    this.addFaqForm = new FormGroup({
      'title': new FormControl('', [Validators.required, Validators.pattern('')]),
      'description': new FormControl('', [Validators.required, Validators.pattern('')])
    })
  }


  // add faq english
  addFaq() {
    let request = {
      'answer': this.addFaqForm.value.description,
      'question': this.addFaqForm.value.title,
      "topicKey": "string",
      "topicName": "string",

    }
    let url = `wallet/admin/add-new-FAQ`
    console.log('f', 'jd');

    this.service.post(url, request).subscribe((res: any) => {
      if (res.status = 200) {
        console.log('jjh', res);

        this.service.toasterSucc(res.message)
        this.route.navigate(['/list-faq-withdral'])
      }
    }, err => {

      this.service.hideSpinner();
      if (err['status'] == '401') {
        this.service.onLogout();
        this.service.toasterErr('Unauthorized Access');
      } else {
        this.service.toasterErr('Something Went Wrong');
      }
    })

  }



}
