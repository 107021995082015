import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiceService } from '../service.service';

@Component({
  selector: 'app-delisting-policy',
  templateUrl: './delisting-policy.component.html',
  styleUrls: ['./delisting-policy.component.css']
})
export class DelistingPolicyComponent implements OnInit {

  id: any;
  about: any;
  dataa: any = [];
  pagekey
  constructor(public service: ServiceService, public route: ActivatedRoute, private router: Router) { }

  ngOnInit() {
    this.route.params.subscribe(x => {

      this.pagekey = x['id'];

    })
    this.getListCode();
  }

  // Get List Code by admin
  getListCode() {
    this.service.showSpinner();
    // http://182.72.203.244:3062/static/get-static-page-data-by-page-key?=About%20Us
    // static/get-static-page-data-by-page-key?pageKey=1
    this.service.get('static/get-static-page-data?pageKey=DELISTING POLICY').subscribe(res => {

      this.service.hideSpinner();
      if (res['status'] == 200) {
        // this.dataa = res['data'];
        // this.about = data.filter(x=>(x.staticContentId == this.id))
        setTimeout(() => {
          this.dataa = res['data'];
        }, 500);
      }
    }, err => {

      this.service.hideSpinner();
      if (err['status'] == '401') {
        // this.service.toasterErr('Unauthorized Access');
        this.service.onLogout();
      } else {
        // this.service.toasterErr('Something Went Wrong');
      }
    })
  }

  // Save Abou Us Functionality
  saveAboutUS() {
    var apiReq = {
      "pageKey": this.dataa.pageKey,
      "pageData": this.dataa.pageData
    }
    this.service.showSpinner();
    this.service.post('static/update-static-content-data', apiReq).subscribe(res => {

      this.service.hideSpinner();
      if (res['status'] == 200) {
        this.getListCode();
        this.router.navigate[('/statics-content')]

        this.service.toasterSucc('About Us Updated Successfully')
      } else {
        this.service.toasterErr('About Us Not Updated')
      }
    }, err => {

      this.service.hideSpinner();
      if (err['status'] == '401') {
        this.service.onLogout();
        this.service.toasterErr('Unauthorized Access');
      } else {
        this.service.toasterErr('Something Went Wrong');
      }
    })
  }

}
