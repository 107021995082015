import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { ServiceService } from '../service.service';



@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  loginData: any;
  Obj: any;
  ipAddress: any;
  view: boolean = false;


  constructor(
    private router: Router,

    private fb: FormBuilder,
    private http: HttpClient,
    private service: ServiceService
  ) { }

  ngOnInit() {
    this.checkInput();
    this.getIP();
  }
  // get IP Address
  getIP() {
    this.service.getIPAddress().subscribe((res: any) => {
      this.ipAddress = res.ip;
    });
  }

  checkInput() {
    let remData = JSON.parse(localStorage.getItem('rememberMe')) ? JSON.parse(localStorage.getItem('rememberMe')) : '';
    let pass = ''
    if (remData != '') {
      pass = window.atob(remData.password)
    }

    this.loginForm = this.fb.group({
      email: new FormControl(remData.email, Validators.pattern(/^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,9}|[0-9]{1,3})(\]?)$/i)),
      password: [pass, [Validators.required]],
      rememberMe: new FormControl(false)

    })
  }

  onLogin() {
    let apiReqData = {
      email: this.loginForm.value.email,
      password: this.loginForm.value.password,
      userAgent: "ADMIN",
      location: "",
      ipAddress: this.ipAddress
    }
    this.service.showSpinner();
    this.service.post("auth", apiReqData).subscribe((res: any) => {
      this.service.hideSpinner();
      if (res['status'] == '200') {
        localStorage.setItem('Auth', res['data']['token']);
        if (this.loginForm.value.rememberMe == true) {
          let remData = {
            "email": this.loginForm.value.email,
            "password": window.btoa(this.loginForm.value.password)
          }
          localStorage.setItem('rememberMe', JSON.stringify(remData))
        }
        this.service.changeLoginSub('login');
        window.location.reload()

        this.router.navigate(['dashboard']);
      }
    },
      (err: any) => {
        this.service.hideSpinner();
        if (err['status'] == '401') {
          this.service.toasterErr('Unauthorized access.');
        } else {
          this.service.toasterErr('Something went wrong.');
        }
      })
  }

  viewPassword(type) {
    if (type == 'lock') {
      this.view = true
    } else (
      this.view = false
    )
  }

}
