import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiceService } from 'src/app/service.service';

@Component({
  selector: 'app-edit-faq-withdral',
  templateUrl: './edit-faq-withdral.component.html',
  styleUrls: ['./edit-faq-withdral.component.css']
})
export class EditFaqWithdralComponent implements OnInit {

  addFaqForm: FormGroup;
  pageNumber: number = 1
  faqId: any;
  editData: any;
  currTab: any;
  constructor(public route: Router, public service: ServiceService, public active: ActivatedRoute) {
    this.active.queryParams.subscribe((res: any) => {
      this.faqId = res.faqId;
      this.currTab = res.lan;
      console.log('fg', this.faqId);

    })
  }

  ngOnInit(): void {
    this.formValidation();
    this.editFaq()
  }

  formValidation() {
    this.addFaqForm = new FormGroup({
      'title': new FormControl('', [Validators.required, Validators.pattern('')]),
      'description': new FormControl('', [Validators.required, Validators.pattern('')])
    })
  }

  // edit faq english
  editFaq() {

    this.service.get("wallet/admin/get-FAQ-by-id?faqId=" + this.faqId).subscribe((res: any) => {
      if (res.status = 200) {
        console.log('jjh', res);
        this.editData = res.data;
        //  this.service.toasterSucc(res.message);
        this.addFaqForm.patchValue({
          'title': this.editData.question,
          'description': this.editData.answer
        })
        // this.route.navigate(['/list-faq-withdral'])
      }
    }, err => {

      this.service.hideSpinner();
      if (err['status'] == '401') {
        this.service.onLogout();
        this.service.toasterErr('Unauthorized Access');
      } else {
        this.service.toasterErr('Something Went Wrong');
      }
    })

  }





  // update faq english
  updateFaq() {
    let request = {
      'answer': this.addFaqForm.value.description,
      'question': this.addFaqForm.value.title,
      "faqId": this.faqId,
      "topicKey": "string",
      "topicName": "string",

    }
    this.service.post("wallet/admin/update-FAQ-by-id", request).subscribe((res: any) => {
      if (res.status = 200) {
        console.log('jjh', res);

        this.service.toasterSucc(res.message)
        this.route.navigate(['/list-faq-withdral'])
      }
    }, err => {

      this.service.hideSpinner();
      if (err['status'] == '401') {
        this.service.onLogout();
        this.service.toasterErr('Unauthorized Access');
      } else {
        this.service.toasterErr('Something Went Wrong');
      }
    })

  }



}
