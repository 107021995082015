import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiceService } from 'src/app/service.service';

@Component({
  selector: 'app-view-faq-withdral',
  templateUrl: './view-faq-withdral.component.html',
  styleUrls: ['./view-faq-withdral.component.css']
})
export class ViewFaqWithdralComponent implements OnInit {

  viewFaqForm: FormGroup;
  pageNumber: number = 1
  faqId: any;
  editData: any;
  currTab: any;
  constructor(public route: Router, public service: ServiceService, public active: ActivatedRoute) {
    this.active.queryParams.subscribe((res: any) => {
      this.faqId = res.faqId;
      this.currTab = res.lan;
      console.log('fg', this.faqId);

    })
  }

  ngOnInit(): void {
    this.viewFaqForm = new FormGroup({
      'title': new FormControl('', [Validators.required, Validators.pattern(/^[^0-9!@#$%^*()_+|<>,;'"]*$/)]),
      'description': new FormControl('', [Validators.required, Validators.pattern('')])
    })
    this.editFaq()
  }

  // edit faq language
  editFaqLanguage() {
    if (this.currTab == 'English') {
      this.editFaq();
    }
    else if (this.currTab == 'German') {
      this.editFaqGerman();
    }
    else if (this.currTab == 'Spanish') {
      this.editFaqSpanish();
    }
  }

  // edit faq english
  editFaq() {

    this.service.get("wallet/admin/get-FAQ-by-id?faqId=" + this.faqId).subscribe((res: any) => {
      if (res.status = 200) {
        console.log('jjh', res);
        this.editData = res.data;
        //  this.service.toasterSucc(res.message);
        this.viewFaqForm.patchValue({
          'title': this.editData.question,
          'description': this.editData.answer
        })
        //  this.route.navigate(['/faq-management'])
      }
    }, err => {

      this.service.hideSpinner();
      if (err['status'] == '401') {
        this.service.onLogout();
        this.service.toasterErr('Unauthorized Access');
      } else {
        this.service.toasterErr('Something Went Wrong');
      }
    })

  }

  // edit faq german
  editFaqGerman() {

    this.service.get("static/get-german-faq-detail?faqId=" + this.faqId).subscribe((res: any) => {
      if (res.status = 200) {
        console.log('jjh', res);
        this.editData = res.data;
        this.service.toasterSucc(res.message);
        this.viewFaqForm.patchValue({
          'title': this.editData.question,
          'description': this.editData.answer
        })
      }
    }, err => {

      this.service.hideSpinner();
      if (err['status'] == '401') {
        this.service.onLogout();
        this.service.toasterErr('Unauthorized Access');
      } else {
        this.service.toasterErr('Something Went Wrong');
      }
    })

  }

  // edit faq spanish
  editFaqSpanish() {
    this.service.get("static/get-spanish-faq-detail?faqId=" + this.faqId).subscribe((res: any) => {
      if (res.status = 200) {
        console.log('jjh', res);
        this.editData = res.data;
        this.service.toasterSucc(res.message);
        this.viewFaqForm.patchValue({
          'title': this.editData.question,
          'description': this.editData.answer
        })
      }
    }, err => {

      this.service.hideSpinner();
      if (err['status'] == '401') {
        this.service.onLogout();
        this.service.toasterErr('Unauthorized Access');
      } else {
        this.service.toasterErr('Something Went Wrong');
      }
    })

  }


}
