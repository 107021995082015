import { Component, OnInit } from '@angular/core';
import { Router, Event, NavigationEnd } from '@angular/router';
import { ServiceService } from '../service.service';
declare var $: any;

@Component({
  selector: 'app-sidemenu',
  templateUrl: './sidemenu.component.html',
  styleUrls: ['./sidemenu.component.css']
})
export class SidemenuComponent implements OnInit {
  currUrl: string;
  isLoggedIn: boolean;
  userDetail: any = {};
  userID: any;
  previlage: any = [];
  getperm: any;
  getrole: any;
  getpermission: any;
  dashboardflag: boolean = false;
  staticflag: boolean = false;
  staticusermgmt: boolean = false;
  staticfeemgmt: boolean = false;
  staticwalletmgmt: boolean = false;
  statickycmgmt: boolean = false;
  statichotcoldmgmt: boolean = false;
  usermanagementflag: boolean = false;
  permissionList: any;
  logsmgmt: boolean = false;
  ticketmgmt: boolean = false;
  trademgmt: boolean = false;
  disputemgmt: boolean = false;
  bankmgmt: boolean = false;
  feemgmt: boolean = false;
  role: string;
  flag: boolean = false;
  adminmgmt: boolean = false;
  settingmgmt: boolean = false;
  advertisementmgmt: boolean = false;
  reset: boolean;

  constructor(private routes: Router, public service: ServiceService) {
    routes.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.currUrl = event.url.split('/')[1];
        if (this.service.isLoggedIn()) {
          if ((this.currUrl == `login` || this.currUrl == `forgot-password` || this.currUrl == `reset-password` || this.currUrl == ``)) {
            this.routes.navigate([`/dashboard`])
          }
        } else {
          if (!(this.currUrl == `login` || this.currUrl == `forgot-password` || this.currUrl == (`reset-password`) || this.currUrl == ``)) {
            this.routes.navigate([`/login`])
          }
        }
      }
    })
  }

  ngOnInit() {
    // login or when profile edit
    this.service.loginObs.subscribe(response => {
      if (response == 'login') {
        this.isLoggedIn = true;
        this.myProfile();
      }
      else {
        this.isLoggedIn = false;
      }
    })

    // when page refresh
    if (this.service.isLoggedIn()) {
      this.isLoggedIn = true;
      this.myProfile();
    } else {
      this.isLoggedIn = false;
    }
  }

  // get profile
  myProfile() {
    var url = `account/my-account?userId=${localStorage.getItem('userId')}`;
    // this.service.showSpinner();
    this.service.get(url).subscribe(res => {
      // this.service.hideSpinner();
      if (res['status'] == 200) {
        this.userDetail = res['data'];
        this.userID = res['data'].userId;
        this.previlage = res['data']['previlage'];
        this.role = res['data'].role;
        localStorage.setItem('userId', this.userID);
        localStorage.setItem('permission', this.previlage);
        localStorage.setItem('usertype', this.role);
        localStorage.setItem('adminEmail', this.userDetail.email)
        //this.getperm=JSON.parse(localStorage.getItem('permission'))
        this.getrole = (localStorage.getItem('usertype'))
        this.getpermission = (localStorage.getItem('permission'))
        this.permissionList = this.getpermission.split(",");
        if (this.role == "SUBADMIN") {
          this.flag = true;
        }
        for (let i = 0; i < this.previlage.length; i++) {
          if (this.previlage[i] == "DASHBOARD") {
            this.dashboardflag = true
          }
          if (this.previlage[i] == "STAFF_MANAGEMENT") {
            this.staticusermgmt = true
          }
          if (this.previlage[i] == "USER_MANAGEMENT") {
            this.usermanagementflag = true
          }
          if (this.previlage[i] == "WALLET_MANAGEMENT") {
            this.staticwalletmgmt = true
          }
          if (this.previlage[i] == "KYC_MANAGEMENT") {
            this.statickycmgmt = true
          }
          if (this.previlage[i] === "HOT_COLD_LIMIT_MANAGEMENT") {
            this.statichotcoldmgmt = true
          }
          if (this.previlage[i] === "STATIC_CONTENT") {
            this.staticflag = true
          }
          if (this.previlage[i] === "LOGS_MANAGEMENT") {
            this.logsmgmt = true
          }
          if (this.previlage[i] === "TICKET_MANAGEMENT") {
            this.ticketmgmt = true
          }
          if (this.previlage[i] === "TRADE_MANAGEMENT") {
            this.trademgmt = true
          }
          if (this.previlage[i] === "DISPUTE_MANAGEMENT") {
            this.disputemgmt = true
          }
          if (this.previlage[i] === "BANK_MANAGEMENT") {
            this.bankmgmt = true
          }
          if (this.previlage[i] === "FEE_MANAGEMENT") {
            this.feemgmt = true
          }
          if (this.previlage[i] === "ADMIN_MANAGEMENT") {
            this.adminmgmt = true
          }
          if (this.previlage[i] === "SETTINGS") {
            this.settingmgmt = true
          }
          if (this.previlage[i] === "ADVERTISEMENTS") {
            this.advertisementmgmt = true
          }
        }
      } else {
        this.service.toasterErr(res['message']);
      }
    }, err => {
      // this.service.hideSpinner();
      if (err['status'] == '401') {
        this.service.onLogout();
        this.service.toasterErr('Unauthorized Access.');
      } else {
        this.service.toasterErr('Something Went Wrong.');
      }
    })
  }

  // logout
  logoutModalOpen() {
    $('#logoutModal').modal('show');
  }

  onLogout() {
    $('#logoutModal').modal('hide');
    this.service.onLogout();
    window.location.reload();
    
  }

  ready() {
    $("#toggle_Menu").click(function () {
      $(".body-class").toggleClass("toggle-wrapper")
    });
  }


}
