import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { ServiceService } from 'src/app/service.service';

@Component({
  selector: 'app-fd-view',
  templateUrl: './fd-view.component.html',
  styleUrls: ['./fd-view.component.css']
})
export class FdViewComponent implements OnInit {
  fixedDepositId:any
  editData:any
  constructor(private router: Router,private activatedroute:ActivatedRoute,public commonService:ServiceService) { }

  ngOnInit() {
    this.activatedroute.queryParams.subscribe((res) => {
      this.fixedDepositId = res.fixedDepositId;
    })
    this.fdView()
  }
  back() {
    this.router.navigate(['/fd-list'])
  }
  

  fdView(){
    
    this.commonService.showSpinner()
this.commonService.get("wallet/view-by-id-fixed-deposit?fixedDepositId="+this.fixedDepositId).subscribe((res:any)=>{
  if (res.status=200) {
    console.log('jjh', res);
    this.editData=res.data;
    this.commonService.hideSpinner()

  }
 },err=>{

   this.commonService.hideSpinner();
   if(err['status']=='401'){
    //  this.commonService.onLogout();
     this.commonService.toasterErr('Unauthorized Access');
   }else{
   this.commonService.toasterErr('Something Went Wrong');
}
 })

} 
toFixed(number ,toLength : number){
  return Number(number).toFixed(toLength)
}
}
